<template>
    <section class="ver-cotizacion-cliente container-fluid">
        <navbar-admin titulo="Cotizaciones">
            <el-tooltip content="Editar" placement="bottom" effect="light" visible-arrow>
                <button  class="btn btn-square32-5d ml-2 cr-pointer"
                    :class="permitAction('cotizaciones.clientes.editar') ? 'cr-pointer' : 'cr-not-allowed'" 
                    @click="functionPermitAction('cotizaciones.clientes.editar', editarCotizacion)"
                >
                    <i class="icon-pencil-outline text-white" />
                </button>
            </el-tooltip>
            <el-tooltip content="Eliminar" placement="bottom" effect="light" visible-arrow>
                <button class="btn btn-square32-5d ml-2 cr-pointer"
                    :class="permitAction('cotizaciones.clientes.eliminar') ? 'cr-pointer' : 'cr-not-allowed'" 
                    @click="functionPermitAction('cotizaciones.clientes.eliminar', eliminarCotizacion)"
                >
                    <i class="icon-trash-can-outline text-white" />
                </button>
            </el-tooltip>
        </navbar-admin>
        <titulo-divisor titulo="Válvula 5214 2000" />
        <div v-if="Object.entries(cotizacionDetalle).length" class="row justify-content-center">
            <div class="col-12 col-lg-9">
                <div class="shadow29 br-10 pb-2">
                    <!-- info top -->
                    <div class="row bg-general text-white br-t-10 f-13 f-500 py-3 mx-0">
                        <div class="col-3 text-center">
                            <p>Cotización: {{cotizacionDetalle.nombre}}</p>
                        </div>
                        <div class="col-3 text-center">
                            <p>Fecha: {{ cotizacionDetalle.created_at | helper-fecha('DD/MM/YYYY') }}</p>
                        </div>
                        <div class="col-3 text-center">
                            <p>Oportunidad: {{cotizacionDetalle.cotizador_equipos[0].oportunidad.nombre || ''}}</p>
                        </div>
                        <div class="col-3 text-center">
                            <p>País: {{cotizacionDetalle.configuracion_pais_utilidad.pais.pais || ''}} </p>
                        </div>
                    </div>
                    <!-- porcentajes -->
                    <div class="row my-4 mx-0 justify-content-center">
                        <div class="col-4 d-flex">
                            <p class="f-12 mr-2 my-auto">Porcentaje de utilidad país:</p>
                            <div class="border bg-f6 f-12 text-5d br-3 w-75px text-center d-middle-center">
                                {{cotizacionDetalle.porcentaje_utilidad_pais}}%
                            </div>
                        </div>
                        <div class="col-4 d-flex">
                            <p class="f-12 mr-2 my-auto">Porcentaje de utilidad general</p>
                            <div class="border bg-f6 f-12 text-5d br-3 w-75px text-center d-middle-center">
                                {{cotizacionDetalle.porcentaje_utilidad}}%
                            </div>
                        </div>
                        <div class="col-auto d-flex icon-option">
                            <el-popover v-model="visibleEditarPorcentaje" placement="bottom-end" width="197" trigger="click" class="px-0" :disabled="!permitAction('cotizaciones.clientes.editar.porcentaje.utilidad')">
                                <div class="row mx-0 justify-content-center f-600 text-general f-12 text-center">
                                    Editar porcentaje de utilidad general
                                </div>
                                <hr class="my-2" />
                                <ValidationObserver ref="validatorPorcentajeUtilidad">
                                    <div class="row mx-0 justify-content-center my-2">
                                        <div class="col-10 my-2">
                                            <p class="input-label-top">Valor del porcentaje</p>
                                            <ValidationProvider v-slot="{errors}" name="valor" rules="required">
                                                <el-input v-model="valorPorcentajeGeneral" size="small" placeholder="%" />
                                                <vee-error :text="errors[0]" />
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </ValidationObserver>
                                <hr />
                                <div class="text-right m-0">
                                    <button class="btn btn-cerrar f-12" @click="visibleEditarPorcentaje = !visibleEditarPorcentaje">Cerrar</button>
                                    <button class="btn btn-crear f-12 ml-2" @click="handleEditarPorcentaje">Agregar</button>
                                </div>
                                <el-tooltip slot="reference" content="Editar utilidad" placement="bottom" effect="light" visible-arrow>
                                    <i 
                                        class="icon-pencil f-18 cr-pointer" 
                                        :class="permitAction('cotizaciones.clientes.editar.porcentaje.utilidad') ? 'cr-pointer' : 'cr-not-allowed'" 
                                        @click="functionPermitAction('cotizaciones.clientes.editar.porcentaje.utilidad')"
                                    />
                                </el-tooltip>
                            </el-popover>
                            <el-tooltip content="Historial cambios" placement="right" effect="light" visible-arrow>
                                <i class="icon-history f-18 cr-pointer" @click="verHistorial" />
                            </el-tooltip>
                        </div>
                    </div>
                    <!-- valor total -->
                    <div class="row my-3 mx-0">
                        <el-tooltip content="Σ etapas * ( 1 + % de utilidad general )" effect="light" visible-arrow placement="bottom">
                            <div class="indicador-g mx-auto">
                                <div class="my-auto">
                                    <p class="px-2 f-13"> Valor total de la cotización </p>
                                </div>
                                <div class="item">
                                    <span class="pr-1">{{ formatoMoneda(cotizacionDetalle.total_cotizacion) }}</span>
                                </div>
                            </div>
                        </el-tooltip>
                    </div>
                    <!-- etapas -->
                    <div class="row my-3 titulo-divisor mx-0">
                        <div class="col-auto my-auto d-middle-center">
                            <p class="f-600 f-14 ucfirst">Etapas</p>
                        </div>
                        <div class="col my-auto px-0">
                            <hr class="my-0 bg-white" />
                        </div>
                        <div class="col-auto my-auto">
                            <div class="d-middle icon-option">
                                <el-popover v-model="visibleAgregarEtapa" placement="bottom-end" width="360" trigger="click" class="px-0" :disabled="!permitAction('cotizaciones.clientes.agregar.etapas')">
                                    <div class="row mx-0 justify-content-center f-600 text-general">
                                        Agregar etapa
                                    </div>
                                    <hr />
                                    <ValidationObserver ref="validatorAgregarEtapa">
                                        <div class="row mx-0 justify-content-center my-2">
                                            <div class="col-10 my-2">
                                                <p class="input-label-top">Etapas</p>
                                                <ValidationProvider v-slot="{errors}" name="etapa" rules="required">
                                                    <el-select v-model="etapaSelect" placeholder="Seleccionar etapa" filterable size="small" class="w-100">
                                                        <el-option v-for="item in etapas" :disabled="item.disabled" :key="item.value" :label="item.nombre" :value="item.id" />
                                                    </el-select>
                                                    <vee-error :text="errors[0]" />
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </ValidationObserver>
                                    <hr />
                                    <div class="text-right m-0">
                                        <button class="btn btn-cerrar f-12" @click="visibleAgregarEtapa = !visibleAgregarEtapa">Cerrar</button>
                                        <button class="btn btn-crear f-12 ml-2" @click="handleAgregarEtapa">Agregar</button>
                                    </div>
                                    <el-tooltip slot="reference" content="Agregar etapa" effect="light" visible-arrow>
                                        <div class="wh-23 rounded-circle bg-5d icon-option-hover text-white cr-pointer mx-2 d-middle-center">
                                            <i class="icon-plus" 
                                                :class="permitAction('cotizaciones.clientes.agregar.etapas') ? 'cr-pointer' : 'cr-not-allowed'" 
                                                @click="functionPermitAction('cotizaciones.clientes.agregar.etapas')"
                                            />
                                        </div>
                                    </el-tooltip>
                                </el-popover>
                                <el-tooltip content="Eliminar etapa" effect="light" visible-arrow>
                                    <div 
                                        class="wh-23 rounded-circle bg-5d icon-option-hover text-white cr-pointer mx-2 d-middle-center" 
                                        :class="permitAction('cotizaciones.clientes.eliminar.etapas') ? 'cr-pointer' : 'cr-not-allowed'" 
                                        @click="functionPermitAction('cotizaciones.clientes.eliminar.etapas', eliminarEtapaSeleccionada, [idEtapaSelected])"
                                    >
                                        <i class="icon-trash-can-outline" />
                                    </div>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                    <!-- listado etapas -->
                    <div class="row mx-0 my-3">
                        <div v-for="(data,idx) in cotizacionDetalle.cotizador_equipos_etapas" :key="idx" class="col-auto mb-3" @click="seleccionarEtapa(data.almacen_etapas.id)">
                            <pill-etapa :nombre="data.almacen_etapas.nombre" :id_etapa="data.almacen_etapas.id" :class="`${idEtapaSelected == data.almacen_etapas.id ? 'selected':''}`" /> 
                        </div>
                    </div>
                    <div v-if="cotizacionDetalle.cotizador_equipos_etapas.length">
                        <div class="row mx-0 justify-content-center">
                            <div v-loading="loading" class="col-auto bg-a5 br-5 text-white f-12">
                                <p class=" py-2 px-3">
                                    Valor final de la etapa:
                                    <span class="f-600">{{ formatoMoneda(valorFinalEtapa.total) }}</span>
                                </p>
                            </div>
                        </div>
                        <!-- Listado de Configuraciones -->
                        <listado-configuraciones :configuraciones="configuraciones"/>
                    </div>
                    <div v-else>
                        <p class="text-muted text-center f-600 f-18 mb-3">Sin etapas</p>
                    </div>

                </div>
            </div>
        </div>
        <!-- partials -->
        <modal-eliminar-cotizacion-cliente ref="abrirModalEliminarCotizacion2" />
        <modal-eliminar ref="abrirModalEliminarEtapaSeleccionada" title="Eliminar etapa" mensaje="¿Está seguro que desea eliminar esta etapa?" @delete="handleEliminarEtapa" />
        <modal-editar-info-cotizacion-cliente ref="openModalEditarCotizacionInfo" />
        <modal-historial-cambios-porcentaje ref="openModalHistorialCambios" />
        <modal-eliminar ref="abrirModalEliminarCotizacion" title="Eliminar cotizacion" mensaje="¿Está seguro que desea eliminar esta cotización?" mensaje2="" @delete="handleEliminarCotizacion" />
    </section>
</template>

<script>
import modalEliminarCotizacionCliente from './partials/modalEliminarCotizacionCliente'
import listadoConfiguraciones from './components/listadoConfiguraciones'
import modalEditarInfoCotizacionCliente from './partials/modalEditarInfoCotizacionCliente'
import modalHistorialCambiosPorcentaje from './partials/modalHistorialCambiosPorcentaje'
import pillEtapa from './components/pillEtapa'
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
    components: {
        modalEliminarCotizacionCliente,
        modalEditarInfoCotizacionCliente,
        modalHistorialCambiosPorcentaje,
        pillEtapa,
        listadoConfiguraciones,
    },
    data() {
        return {
    
            visibleAgregarEtapa: false,
            visibleAgregarConfiguracion: false,
            visibleEditarPorcentaje: false,
            etapaName: '',
            idEtapaSelected: null,
            etapaSelect: '',
            valorPorcentajeGeneral: '',
            etapas:[],
            loading:false,
        }
    },
    computed: {
        ...mapGetters({
            cotizacionDetalle: 'cotizacion/cotizacionClientesDetalle/cotizacionDetalle',
            configuraciones: 'cotizacion/cotizacionClientesDetalle/configuraciones',
            estapasEquipos: 'cotizacion/cotizacionClientes/estapasEquipos',
            valorFinalEtapa: 'cotizacion/cotizacionClientesDetalle/valorFinalEtapa',
        }),
    },
    async created(){
        await this.listarCotizacionesDetalle();
        this.listarEtapas()
    },
    methods: {
        ...mapActions({
            Action_get_cotizacion_detalle: 'cotizacion/cotizacionClientesDetalle/Action_get_cotizacion_detalle',
            Action_get_etapas_equipos: 'cotizacion/cotizacionClientes/Action_get_etapas_equipos',
            Action_post_agregar_etapa: 'cotizacion/cotizacionClientesDetalle/Action_post_agregar_etapa',
            Action_get_list_configuracion: 'cotizacion/cotizacionClientesDetalle/Action_get_list_configuracion',
            Action_put_actualizar_porcentaje_utilidad: 'cotizacion/cotizacionClientesDetalle/Action_put_actualizar_porcentaje_utilidad',
            Action_get_cotizador_historial: 'cotizacion/cotizacionClientesDetalle/Action_get_cotizador_historial',
            Action_get_list_valor_final_etapa: 'cotizacion/cotizacionClientesDetalle/Action_get_list_valor_final_etapa',
            Action_delete_cotizacion: 'cotizacion/cotizacionClientesDetalle/Action_delete_cotizacion',
            Action_delete_etapa: 'cotizacion/cotizacionClientesDetalle/Action_delete_etapa',



        }),
        ...mapMutations({
            set_configuraciones: 'cotizacion/cotizacionClientesDetalle/set_configuraciones',
            set_valor_final_etapa: 'cotizacion/cotizacionClientesDetalle/set_valor_final_etapa',
            set_valor_final_etapa: 'cotizacion/cotizacionClientesDetalle/set_valor_final_etapa',

        }),
        async listarCotizacionesDetalle(){
            const id = this.$route.params.id_cotizacion
            await this.Action_get_cotizacion_detalle(id);
            this.valorPorcentajeGeneral = this.cotizacionDetalle.porcentaje_utilidad
            if (this.cotizacionDetalle.cotizador_equipos_etapas.length) {
                this.seleccionarEtapa(this.cotizacionDetalle.cotizador_equipos_etapas[0].id_etapa)
            }
        },
        eliminarCotizacion() {
            //this.$refs.abrirModalEliminarCotizacion.toggle() // modal eliminar
            this.$refs.abrirModalEliminarCotizacion.toggle() // modal en partials
        },
        async listarEtapas(){
            this.etapas = []
            await this.Action_get_etapas_equipos(this.cotizacionDetalle.cotizador_equipos[0].id_oportunidad)
            let etapas = this.cotizacionDetalle.cotizador_equipos_etapas
            let arrayIds = []

            if (this.estapasEquipos.length) {
                if (etapas.length) {
                    arrayIds = etapas.map(e => e.almacen_etapas.id)
                }
                this.estapasEquipos.map(e => {
                    if (arrayIds.includes(e.id)) {
                        e.disabled = true
                    }else{
                        e.disabled = false
                    }
                    this.etapas.push(e)
                })
                
            }
            
        },
        handleEliminarCotizacion() {
            this.Action_delete_cotizacion(this.$route.params.id_cotizacion)
            this.$router.push({
                name: 'cotizaciones.clientes',
            })
        },
        editarCotizacion() {
            this.$refs.openModalEditarCotizacionInfo.toggle()
        },
        async handleAgregarEtapa() {
            const payload = {
                id_cotizador: this.$route.params.id_cotizacion,
                id_cotizador_equipos_etapas: this.cotizacionDetalle.cotizador_equipos[0].id,
                id_etapa:this.etapaSelect
            }
            this.visibleAgregarEtapa = !this.visibleAgregarEtapa
            await this.Action_post_agregar_etapa(payload)
            this.etapaSelect = ''
            this.listarEtapas()
            // console.log('add etapa');
        },
        seleccionarEtapa(id_etapa){
            this.idEtapaSelected = id_etapa
            let configuraciones = this.cotizacionDetalle.cotizador_equipos_etapas.find(e => e.id_etapa == id_etapa)
            this.set_configuraciones(configuraciones.cotizador_equipos_etapas_configuraciones)
            this.listarSelectConfiguracion()
            this.valorFinalEtapaCotizador(configuraciones)
            // action listar configuraciones
        },
        async valorFinalEtapaCotizador(configuraciones){
            if (Object.entries(configuraciones).length) {
                const payload = {
                    id_etapa: configuraciones.id,
                    id_cotizador: this.$route.params.id_cotizacion
                }
                this.loading = true
                await this.Action_get_list_valor_final_etapa(payload)
                this.loading = false
            }else{
                this.set_valor_final_etapa(0)
            }
        },
        async listarSelectConfiguracion(){
            if (!this.configuraciones.length) return 
            
            let payload = {
                id_cotizador:this.$route.params.id_cotizacion,
                id_etapa:this.configuraciones[0].id_cotizador_equipos_etapas
            }
            await this.Action_get_list_configuracion(payload)
        },
        eliminarEtapaSeleccionada(id_etapa){
            if (this.idEtapaSelected == null) {
                this.$notify({title: 'Seleccionar una etapa', type:'warning'})
            } else {
                this.$refs.abrirModalEliminarEtapaSeleccionada.toggle()
                
            }
        },

        async handleEliminarEtapa(){
            const etapa = this.cotizacionDetalle.cotizador_equipos_etapas.find(e => e.id_etapa == this.idEtapaSelected)

            const payload = {
                id_cotizador: this.$route.params.id_cotizacion,
                id_etapa: etapa.id
            }
            await this.Action_delete_etapa(payload)
            await this.listarCotizacionesDetalle();
            await this.listarEtapas()
            this.set_configuraciones([])
            this.idEtapaSelected = null
            this.set_valor_final_etapa(0)

        },

        deleteConfiguration(){
            this.$refs.abrirModalEliminarConfigurar.toggle()
        },
        updateConfiguration(){
            console.log('update configuration info');
        },
        async verHistorial(){
            await this.Action_get_cotizador_historial(this.$route.params.id_cotizacion)
            this.$refs.openModalHistorialCambios.toggle()
            console.log('verHistorial');
        },
        verConfiguracion(){
            this.$router.push({ name: 'cotizaciones.ver.cliente.configuracion.materiales' })
        },
        async handleEditarPorcentaje(){
            const payload = {
                id_cotizador: this.$route.params.id_cotizacion,
                porcentaje_utilidad: this.valorPorcentajeGeneral
            }
            this.visibleEditarPorcentaje = false
            await this.Action_put_actualizar_porcentaje_utilidad(payload)
        }
    },
}
</script>

<style lang="scss" scoped>
.ver-cotizacion-cliente{
    background: white;
    .base-indicador{
        border-radius: 4px;
        height: 36px;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }
    .base-indicador-item{
        border-radius: 0px 1px 1px 0px;
        padding: 0px 10px;
        display: flex;
        align-items: center;
        color: #fff;
    }
    .indicador-g{
        border: 1px solid var(--color-general);
        color: var(--color-general);
        @extend .base-indicador;
        .item{
            background: var(--color-general);
            @extend .base-indicador-item;
        }
    }
    .w-75px{
        width: 75px;
    }
    .bg-a5{
        background: #A5A5A5;
    }
    .text-a5{
        color: #A5A5A5;
    }
  
}
</style>