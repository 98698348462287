<template>
    <div class="etapa-pill shadow29 rounded-pill text-center f-12 d-middle-center cr-pointer"  @click="seleccionarEtapa(id_etapa)">
        <p class="tres-puntos px-2">{{ nombre }}</p>
    </div>
</template>

<script>
export default {
    props: {
        id_etapa: {
            type: Number,
            default: null
        },
        nombre: {
            type: String,
            default: '---'
        },
    },
    data() {
        return {
            
        }
    },
    methods: {
        seleccionarEtapa(id) {
            this.$emit('seleccionarEtapa', id)
        }
    },
}
</script>

<style lang="scss" scoped>
.etapa-pill{
    width: 145px;
    height: 29px;
    &.selected{
        background: var(--color-general);
        color: #fff;
    }
}
</style>