<template>
    <modal ref="actualizarConfiguracion" titulo="Actualizar configuración" adicional="Actualizar" @adicional="actualizarConfiguracion">
        <div class="m-3">
           <div class="row">
               <p class="col-12 f-14 text-center">¿Está seguro que desea actualizar esta configuración?</p>
           </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            
        }
    },
    methods: {
        toggle(){
            this.$refs.actualizarConfiguracion.toggle()
        },
        actualizarConfiguracion(){
            this.$refs.actualizarConfiguracion.toggle()
            this.$emit('actualizar')  
        }
    }
}
</script>

<style lang="css" scoped>
</style>
