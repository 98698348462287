<template>
    <modal ref="eliminarConfiguracion" titulo="Eliminar configuración" adicional="Eliminar" @adicional="eliminarConfiguracion">
        <div class="m-3">
           <div class="row">
               <p class="col-12 f-14 text-center">¿Está seguro que desea eliminar esta configuración?</p>
           </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            
        }
    },
    methods: {
        toggle(){
            this.$refs.eliminarConfiguracion.toggle()
        },
        eliminarConfiguracion(){
            this.$refs.eliminarConfiguracion.toggle()
            this.$emit('eliminar')  
        }
    }
}
</script>

<style lang="css" scoped>
</style>
