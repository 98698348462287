<template>
    <section>
        <div class="row my-3 titulo-divisor mx-0">
            <div class="col-auto my-auto d-middle-center">
                <p class="f-600 f-14 ucfirst">Listado de Configuraciones</p>
            </div>
            <div class="col my-auto px-0">
                <hr class="my-0 bg-white" />
            </div>
            <div class="col-auto my-auto">
                <div class="d-middle icon-option">
                    <el-popover v-model="visibleAgregarConfiguracion" placement="bottom-end" width="360" trigger="click" class="px-0" :disabled="!permitAction('cotizaciones.clientes.configuraciones.agregar')">
                        <div class="row mx-0 justify-content-center f-600 text-general">
                            Agregar configuración
                        </div>
                        <hr />
                        <ValidationObserver ref="validatorAgregarConfiguracion">
                            <div class="row mx-0 justify-content-center my-2">
                                <div class="col-10 my-2">
                                    <p class="input-label-top">Configuraciones</p>
                                    <ValidationProvider v-slot="{errors}" name="configuración" rules="required">
                                        <el-select v-model="idConfiguracionSelect" placeholder="Seleccionar configuración" filterable size="small" class="w-100">
                                            <el-option v-for="item in selectConfiguraciones" :key="item.value" :disabled="item.disabled" :label="item.nombre" :value="item.id" />
                                        </el-select>
                                        <vee-error :text="errors[0]" />
                                    </ValidationProvider>
                                </div>
                            </div>
                        </ValidationObserver>
                        <hr />
                        <div class="text-right m-0">
                            <button class="btn btn-cerrar f-12" @click="visibleAgregarConfiguracion = !visibleAgregarConfiguracion">Cerrar</button>
                            <button class="btn btn-crear f-12 ml-2" @click="handleAgregarConfiguracion">Agregar</button>
                        </div>
                        <el-tooltip slot="reference" content="Agregar configuracion" effect="light" visible-arrow>
                            <div class="wh-23 rounded-circle bg-5d icon-option-hover text-white cr-pointer mx-2">
                                <i 
                                    class="icon-plus" 
                                    :class="permitAction('cotizaciones.clientes.configuraciones.agregar') ? 'cr-pointer' : 'cr-not-allowed'" 
                                    @click="functionPermitAction('cotizaciones.clientes.configuraciones.agregar')"
                                />
                            </div>
                        </el-tooltip>
                    </el-popover>
                </div>
            </div>
        </div>
        <div class="row f-12 mx-3 text-general f-600 mb-2">
            <div class="col-10">
                <div class="row">
                    <div class="col-6">
                        <p>Configuraciones</p>
                    </div>
                    <div class="col-2 text-center">
                        <p>Valor</p>
                    </div>
                    <div class="col-2 text-center">
                        <p>$ Utilidad</p>
                    </div>
                    <div class="col-2">
                        <p class="d-middle">
                            Valor final
                            <el-popover placement="bottom" width="460" trigger="hover">
                                <div>
                                    <p style="color:#B3B3B3">(Valor configuración) * (1 + % utilidad configuración + % utilidad país)</p>
                                </div>
                                <i slot="reference" class="icon-information-outline f-17" />
                            </el-popover>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <p>Actualizar</p>
            </div>
        </div>
        <div v-for="(data, index) in configuraciones" :key="index" class="row f-12 mx-3 text-5d my-3 cr-pointer">
            <div class="col-10">
                <div class="row  border rounded-pill" @click.stop="verConfiguracion(data)">
                    <div class="col-6 my-auto">
                        <p class="">{{data.almacen_etapas_configuracion.nombre}}</p>
                    </div>
                    <div class="col-2 text-center my-auto">
                        <p class="">{{data.valor}}</p>
                    </div>
                    <div class="col-2 text-center my-auto">
                        <p class="">{{data.porcentaje_configuracion}}%</p>
                    </div>
                    <div class="col-2 d-middle justify-content-around">
                        <p class="f-600">{{data.valor}}</p>
                        <i 
                            class="icon-close-circle f-20 text-a5" 
                            :class="permitAction('cotizaciones.clientes.configuraciones.eliminar') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click.stop="functionPermitAction('cotizaciones.clientes.configuraciones.eliminar', deleteConfiguration, [data])"
                        />
                    </div>
                </div>
            </div>
            <div class="col-2 text-center">
                <div class="btn-square32-5d ">
                    <i 
                        class="icon-restore f-18 text-white" 
                        :class="permitAction('cotizaciones.clientes.configuraciones.actualizar') ? 'cr-pointer' : 'cr-not-allowed'" 
                        @click.stop="functionPermitAction('cotizaciones.clientes.configuraciones.actualizar', updateConfiguration, [data.id])"
                    />
                </div>
            </div>
        </div>
        <modal-eliminar-configuracion ref="abrirModalEliminarConfigurar" @eliminar="eliminarConfiguracion" />
        <modal-actualizar-configuracion ref="abrirModalActualizarConfigurar" @actualizar="actualizarConfiguracion" />

    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import modalEliminarConfiguracion from '../partials/modalEliminarConfiguracion'
import modalActualizarConfiguracion from '../partials/modalActualizarConfiguracion'

export default {
    components: {
        modalEliminarConfiguracion,
        modalActualizarConfiguracion
    },

    data(){
        return {
            visibleAgregarConfiguracion: false,
            idConfiguracionSelect: '',
            id_configuracion:'',
            id_etapa: '',
        }
    },
    computed: {
        ...mapGetters({
            configuraciones: 'cotizacion/cotizacionClientesDetalle/configuraciones',
            selectConfiguraciones: 'cotizacion/cotizacionClientesDetalle/selectConfiguraciones',
        }),
    },
    methods:{
        ...mapActions({
            Action_delete_configuracion: 'cotizacion/cotizacionClientesDetalle/Action_delete_configuracion',
            Action_get_list_configuracion: 'cotizacion/cotizacionClientesDetalle/Action_get_list_configuracion',
            Action_post_add_configuracion: 'cotizacion/cotizacionClientesDetalle/Action_post_add_configuracion',
            Action_post_actualizar_porcentaje_configuracion: 'cotizacion/cotizacionClientesDetalle/Action_post_actualizar_porcentaje_configuracion',
            Action_get_list_valor_final_etapa: 'cotizacion/cotizacionClientesDetalle/Action_get_list_valor_final_etapa',

        }),
        verConfiguracion(item){
            this.$router.push({
                name: 'cotizaciones.ver.cliente.configuracion.materiales',
                params: {
                    id_cotizacion: this.$route.params.id_cotizacion,
                    id_configuracion: item.id
                }
            })
        },
        deleteConfiguration(item){
            this.id_etapa = item.id_cotizador_equipos_etapas
            this.id_configuracion = item.id
            this.$refs.abrirModalEliminarConfigurar.toggle()
        },
        async eliminarConfiguracion(){
            const payload = {
                id_cotizador: this.$route.params.id_cotizacion,
                id_configuracion: this.id_configuracion,
                id_etapa: this.id_etapa
            }
            await this.Action_delete_configuracion(payload)
            this.id_configuracion = ''
            this.id_etapa = ''
            this.listarSelectConfiguracion();
            this.valorFinalEtapaCotizador(payload.id_etapa)
        },
        updateConfiguration(id){
            this.id_configuracion = id
            this.$refs.abrirModalActualizarConfigurar.toggle()
        },
        actualizarConfiguracion(){
            const payload = {
                id_cotizador: this.$route.params.id_cotizacion,
                id_configuracion: this.id_configuracion,
            }
            this.Action_post_actualizar_porcentaje_configuracion(payload)
        },
        async handleAgregarConfiguracion() {
            const valid = await this.$refs.validatorAgregarConfiguracion.validate();
            if (valid) {
                // action agregar etapa
                const payload = {
                    id_cotizador: this.$route.params.id_cotizacion,
                    id_etapa: this.configuraciones[0].id_cotizador_equipos_etapas,
                    id_configuracion: this.idConfiguracionSelect
                }
                this.visibleAgregarConfiguracion = !this.visibleAgregarConfiguracion
                await this.Action_post_add_configuracion(payload)
                this.idConfiguracionSelect = ''
                this.valorFinalEtapaCotizador(payload.id_etapa)
            }
            await this.listarSelectConfiguracion()
        },
        async listarSelectConfiguracion(){
            let payload = {
                id_cotizador:this.$route.params.id_cotizacion,
                id_etapa:this.configuraciones[0].id_cotizador_equipos_etapas
            }
            await this.Action_get_list_configuracion(payload)
        },
        async valorFinalEtapaCotizador(id_etapa){
            const payload = {
                id_etapa: id_etapa,
                id_cotizador: this.$route.params.id_cotizacion
            }
            await this.Action_get_list_valor_final_etapa(payload)
        },
    }
}
</script>

<style>

</style>