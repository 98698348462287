<template>
    <modal ref="editarInfoCotizacion" titulo="Editar información acerca de la cotización" tamano="modal-lg" adicional="Guardar" @adicional="editarInfoCotizacion">
        <div class="row m-3">
            <div class="col-6 my-2">
                <p class="input-label-top">Introduzca el nombre</p>
                <el-input v-model="model.nombre" size="small" clear="w-100" placeholder="Nombre cotización" />
            </div>
            <div class="col-6 my-2">
                <p class="input-label-top">Oportunidad</p>
                <el-select v-model="model.id_oportunidad" placeholder="Seleccionar oportunidad" filterable size="small" class="w-100" @change="listarEquipos">
                    <el-option v-for="item in oportunidades" :key="item.value" :label="item.nombre" :value="item.id" />
                </el-select>
            </div>
            <div class="col-6 my-2">
                <p class="input-label-top">Equipo</p>
                <el-select v-model="model.id_producto" placeholder="Seleccionar equipo" filterable size="small" class="w-100">
                    <el-option v-for="item in equipos" :key="item.value" :label="item.nombre" :value="item.id" />
                </el-select>
            </div>
            <div class="col-6 my-2">
                <p class="input-label-top">País</p>
                <el-select v-model="model.id_pais" placeholder="Seleccionar país" filterable size="small" class="w-100">
                    <el-option v-for="item in paises" :key="item.value" :label="item.pais" :value="item.id" />
                </el-select>
            </div>    
        </div>
    </modal>
</template>

<script>
import { mapGetters, mapActions} from 'vuex'
export default {
    data(){
        return {
            model: {
                nombre:'',
                id_oportunidad:'',
                id_producto: '',
                id_pais: '',
                id_cotizador_equipo:''
            },
        }
    },
    computed: {
        ...mapGetters({
            cotizacionDetalle: 'cotizacion/cotizacionClientesDetalle/cotizacionDetalle',
            oportunidades: 'selects/selects/select_projects',
            equipos: 'cotizacion/cotizacionClientes/equipos',
            paises: 'cotizacion/cotizacionClientes/paises',
        }),
    },
    mounted(){
        this.Action_get_select_projects()
        this.Action_get_paises()

    },
    methods: {
        ...mapActions({
            Action_get_select_projects: 'selects/selects/Action_get_select_projects',
            Action_get_equipos_ops: 'cotizacion/cotizacionClientes/Action_get_equipos_ops',
            Action_get_paises: 'cotizacion/cotizacionClientes/Action_get_paises',
            Action_put_editar_cotizacion: 'cotizacion/cotizacionClientesDetalle/Action_put_editar_cotizacion',
            Action_get_etapas_equipos: 'cotizacion/cotizacionClientes/Action_get_etapas_equipos',

        }),
        listarEtapa(){
            
        },
        toggle(){
            this.cargarDatos()
            this.$refs.editarInfoCotizacion.toggle()
        },
        async editarInfoCotizacion(){
            const payload = {
                id_cotizador: this.$route.params.id_cotizacion, 
                data: this.model,
            }
            await this.Action_put_editar_cotizacion(payload)
            await this.Action_get_etapas_equipos(this.model.id_oportunidad)
            this.$refs.editarInfoCotizacion.toggle()
        },
        cargarDatos(){
            this.model.nombre = this.cotizacionDetalle.nombre
            this.model.id_oportunidad = this.cotizacionDetalle.cotizador_equipos[0].id_oportunidad
            this.model.id_pais = this.cotizacionDetalle.configuracion_pais_utilidad.id
            this.Action_get_equipos_ops(this.model.id_oportunidad)
            this.model.id_producto = this.cotizacionDetalle.cotizador_equipos[0].oportunidad.producto.id
            this.model.id_cotizador_equipo = this.cotizacionDetalle.cotizador_equipos[0].id
        },
        async listarEquipos(){
            this.model.id_producto = ''
            await this.Action_get_equipos_ops(this.model.id_oportunidad)
        },
        limpiar(){
            this.model.nombre = ''
            this.model.id_oportunidad = ''
            this.model.id_producto = ''
            this.model.id_pais = ''
            this.model.id_cotizador_equipo = ''

        }
    }
}
</script>

<style lang="css" scoped>
</style>
