<template>
    <modal ref="historialEtapas" titulo="Historial cambios de porcentaje de utilidad general" tamano="modal-lg">
        <div class="m-3">
           <div class="row text-general border-bottom-g f-14">
               <div class="col-6">
                   <p>Usuario</p>
               </div>
               <div class="col-3 text-center">
                   <p>Fecha de cambio</p>
               </div>
               <div class="col-3 text-center">
                   <p>Porcentaje nuevo</p>
               </div>
           </div>
           <div v-for="(data,i) in cotizadorHistorial" :key="i" class="row text-5d f-12 border-bottom py-2">
               <div class="col-6">
                   <card-mini-user :img="data.user.foto" tamanoImg="32" :nombre="data.user.nombre" />
               </div>
               <div class="col-3 text-center my-auto">
                   <p>{{data.fecha}}</p>
               </div>
               <div class="col-3 text-center my-auto">
                   <p>{{data.porcentaje}}%</p>
               </div>
           </div>
        </div>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    data(){
        return {
            
        }
    },
    computed: {
        ...mapGetters({
            cotizadorHistorial: 'cotizacion/cotizacionClientesDetalle/cotizadorHistorial',
        }),
    },
    methods: {
        toggle(){
            this.$refs.historialEtapas.toggle()
        },
        async historialEtapas(){
            this.$refs.historialEtapas.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
